:root {
  --white: #fff;
  --grey-secondary: #e4e4e4;
  --black: #282828;
  --green: #39b54a;
  --blue: #00b4d2;
  --grey: #60666f;
  --bg: #f1f4f5;
  --yellow: #ffd94d;
  --red: #ca2728;
  background-color: var(--bg);
}
