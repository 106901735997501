.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
.p-datatable .p-sortable-column .p-sortable-column-icon {
  width: 10px !important;
  height: 10px !important;
}
.p-column-filter-menu-button-open,
.p-column-filter-menu-button {
  width: 20px !important;
  height: 20px !important;
}

.p-sortable-column.p-highlight {
  background: #eeffef !important;
  color: #38ca3f !important;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #38ca3f !important;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #c8fec7 !important;
  outline: 0 none !important;
}

.p-component,
.p-dropdown,
.p-dropdown-label {
  font-size: 12px !important;
}

.p-button {
  background-color: #00b4d2 !important;
  border-color: #00b4d2 !important;
}

.p-button-outlined {
  background-color: transparent !important;
  color: #00b4d2 !important;
  border-color: #00b4d2 !important;
}
