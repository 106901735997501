.green {
  @apply bg-green;
}

.blue {
  @apply bg-blue;
}

.yellow {
  @apply bg-yellow;
}

.grey {
  @apply bg-grey;
}

.red {
  @apply bg-red;
}

.disabled {
  @apply pointer-events-none cursor-default bg-grey opacity-10;
}
