@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Nunito';
  src: url('../public/fonts/Nunito-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url('../public/fonts/Nunito-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  src: url('../public/fonts/Nunito-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  src: url('../public/fonts/Nunito-Black.ttf') format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family:
    'Nunito',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.p-filled,
.p-button {
  border-radius: 3px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-scroll {
  overflow: scroll;
}

* {
  font-family:
    'Nunito',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}

.p-component {
  font-family:
    'Nunito',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
}

div .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin-top: 0px;
}
div .p-toast .p-toast-message .p-toast-message-content {
  display: flex;

  align-items: center;
}

.p-button.p-component.p-fileupload-choose {
  max-width: 100%;
}

.p-button.p-component.p-fileupload-choose .p-button-label.p-clickable {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-column-filter.p-fluid.p-column-filter-menu svg {
  width: 10px;
  height: 10px;
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

.no-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.p-datatable * {
  font-size: 12px !important;
  color: #282828;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 5px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.gradient {
  background: rgb(241, 244, 245);
  background: linear-gradient(
    90deg,
    rgba(241, 244, 245, 1) 0%,
    rgba(201, 243, 252, 1) 35%,
    rgba(255, 247, 219, 1) 75%,
    rgba(181, 255, 198, 1) 100%
  );
  animation: gradient 5s infinite linear;
  background-size: 400%;
}

@keyframes gradient {
  0% {
    background-position: 80% 0%;
  }
  50% {
    background-position: 20% 100%;
  }
  100% {
    background-position: 80% 0%;
  }
}

.p-checkbox-box {
  border-width: 1px;
}

.p-checkbox .p-focus {
  box-shadow: none;
}

.ui-paginator-table .p-paginator-page {
  font-size: 12px !important;
}

.ui-paginator-table .p-inputtext,
.ui-paginator-table .p-dropdown-item {
  align-self: center;
  text-align: center;
}

.rbc-allday-cell {
  display: none;
}

.rbc-time-content {
  border-top: none !important;
}

.rbc-timeslot-group:nth-child(1) {
  border-top: 1px solid #ddd;
}

.rbc-day-slot .rbc-events-container {
  border-left: 0;
  margin: 3px !important;
}

.rbc-calendar {
  font-size: 14px !important;
  color: #282828 !important;
}

.p-tree .p-tree-container .p-treenode:focus > .p-treenode-content {
  box-shadow: none !important;
}

.p-password-input:not(.auth).p-inputtext:enabled:focus {
  box-shadow: none !important;
  outline: none !important;
  outline-offset: 0;
  border: none !important;
}

.p-password-input:not(.auth).p-inputtext.p-component.p-password-input {
  border: none !important;
}

.p-dropdown-item-label:has(div[data-type='color']) {
  width: 100%;
}

.p-datepicker.p-component.p-ripple-disabled {
  z-index: 1;
}

.p-confirm-popup-reject.p-button,
.p-confirm-popup-accept.p-button {
  font-weight: 900 !important;
}

.p-confirm-popup-reject.p-button-sm.p-button-text.p-button.p-component {
  background-color: white !important;
  border-color: white !important;
}

.p-confirm-popup-accept.p-button-sm.p-button.p-component {
  background-color: #39b54a !important;
}

.p-password .p-icon-field.p-icon-field-right {
  width: 100%;
}
