.p-button-danger {
  @apply border-none !bg-red focus:shadow-none;
}

.p-confirm-dialog-reject {
  @apply border-none !bg-transparent text-blue focus:shadow-none;
}

.timeInput .p-dropdown-trigger {
  @apply hidden;
}

.timeInput.p-dropdown {
  @apply flex items-center justify-center bg-white;
}

.timeInput.p-dropdown:not(.p-disabled).p-focus {
  @apply border-green bg-green shadow-none;
}

.timeInput.p-dropdown:not(.p-disabled).p-focus .p-dropdown-label {
  @apply text-white;
}

.timeInput .p-dropdown-label {
  @apply max-w-fit;
}

.timeInput.p-dropdown:not(.p-disabled):hover {
  @apply bg-white;
}

.rbc-addons-dnd-resizable {
  @apply p-[5px];
}

.rbc-btn-group {
  @apply flex gap-4;
}

.rbc-btn-group {
  button {
    @apply h-9 min-w-[120px] cursor-pointer rounded-none border-none p-2 text-sm font-black uppercase text-white;
  }
  button:hover,
  button:focus {
    @apply text-white;
  }
  button:nth-child(1n) {
    @apply bg-green;
  }
  button:nth-child(2n) {
    @apply bg-blue;
  }
}

.rbc-btn-group:nth-child(1) {
  button:nth-child(1) {
    @apply bg-grey;
  }
}

.rbc-toolbar-label {
  @apply font-bold;
}

.p-autocomplete-input {
  @apply w-full border-none;
}

.p-inputtext:enabled:focus {
  @apply shadow-none outline-none;
}

.inDevelop {
  @apply pointer-events-none blur-[2px];
}
