.multiselect-dropdown .p-component.p-multiselect-items .p-checkbox {
  display: none;
}
.multiselect .p-multiselect-label-container {
  display: flex;
}
.multiselect .p-multiselect-label {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
.multiselect.green-label .p-multiselect-label-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 12px;
}
.multiselect.green-label .p-multiselect-label {
  background: #39b54a;
  height: 20px;
  color: #fff;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 4px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.multiselect.p-multiselect.p-multiselect-chip .p-multiselect-token {
  margin-right: 0;
}
