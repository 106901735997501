.status-1 {
  @apply border-grey bg-grey-hover;
}

.status-2 {
  @apply border-blue bg-bg-blue;
}

.status-3 {
  @apply border-yellow bg-bg-yellow;
}

.status-4 {
  @apply border-green bg-bg-green;
}

.status-1000 {
  @apply border-red bg-bg-red;
}

.status-100 {
  @apply border-green bg-bg-green;
}

.status-0 {
  @apply border-grey bg-grey-hover;
}

span[data-type='check'] {
  @apply cursor-pointer text-blue underline;
}

.status-1,
.status-2,
.status-3,
.status-4,
.status-1000,
.status-100,
.status-0 {
  @apply whitespace-nowrap rounded-[50px] border border-solid px-2 leading-6;
}
