.p-multiselect-token-label {
  @apply text-xs !text-white;
}

.p-multiselect-token {
  @apply flex items-center gap-[4px];
}

.multiselect-dropdown .p-multiselect-item {
  @apply !text-black;
}
