.selectButton .p-button {
  font-family: 'Nunito' !important;
  @apply !rounded-none !border-grey-secondary !bg-white !px-2 text-[14px] !font-semibold uppercase !text-black !transition-colors duration-500 max-sm:!text-xs;
}

.selectButton .p-highlight {
  @apply !bg-grey-hover !font-extrabold !transition-colors duration-500;
}

.selectButton .p-focus {
  @apply !shadow-none;
}
