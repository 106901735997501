.custom-three .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background-color: transparent;
}

.custom-three .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  box-shadow: unset;
}

.custom-three .p-tree {
  border: 0px;
  padding: 0;
}

.custom-three .p-tree .p-tree-container .p-treenode .p-treenode-content {
  padding: 0;
}
