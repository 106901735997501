.worker .p-dropdown-label.p-inputtext {
  padding-top: 0;
  padding-bottom: 0;
  height: 36px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.worker .p-inputtext:focus,
.worker .p-inputtext:hover {
  border-color: #34c247;
}

.worker.p-dropdown {
  border: 1px solid #39b54a;
  border-radius: 3px;
}
.worker.p-dropdown:not(.p-disabled).p-focus {
  border-color: #34c247;
  box-shadow: 0 0 0 0.2rem #39b54a29;
}

.incorrect.p-dropdown:not(.p-disabled).p-focus {
  border-color: #ca2728 !important;
  box-shadow: 0 0 0 0.2rem #b5393929 !important;
}
.incorrect,
.incorrect .p-inputotp-input.p-inputtext {
  border-color: #ca2728 !important;
}

.worker.p-dropdown:not(.p-disabled):hover {
  border-color: #34c247;
}
.worker .p-datepicker-trigger {
  background-color: #34c247 !important;
  box-shadow: none;
  border: #34c247;
  border-radius: 0 3px 3px 0 !important;
}
.worker-error .p-inputtext {
  border-color: #ca2728 !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  border-radius: 3px 0 0 3px !important;
}
.worker.shadow-none.p-calendar.p-component.p-inputwrapper.p-calendar-w-btn.p-calendar-w-btn-right .p-inputtext {
  border-color: #34c247;
  border-radius: 3px 0 0 3px !important;
}
.worker-panel.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #39b54a;
  background: #39b54a18;
}
.p-fileupload.p-fileupload-basic.p-component.file-upload-workers {
  width: 100%;
}
.file-upload-workers .p-button {
  background-color: rgb(0 180 210 / var(--tw-bg-opacity));
  border-radius: 3px;
  border: 0px;
  height: 36px;
  text-transform: uppercase;
  font-size: 12px;
  width: 100%;
  font-weight: 900;
}

.file-upload-workers-green .p-button {
  background-color: #39b54a !important;
}
.file-upload-workers .p-button .p-button-label.p-clickable {
  font-size: 12px;
  width: 100%;
  font-weight: 900;
  font-family: 'Arial';
}
.file-upload-workers .p-button .p-icon.p-button-icon.p-button-icon-left {
  display: none;
}
