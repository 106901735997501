.multiselect-dropdown .p-checkbox.p-component.p-multiselect-items {
  display: none;
}
.multiselect .p-multiselect-label-container {
  display: flex;
}
.multiselect .p-multiselect-label {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
.multiselect.green-label .p-multiselect-label-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 12px;
}
.multiselect.green-label .p-multiselect-label {
  background: #39b54a;
  height: 20px;
  color: #fff;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 4px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.multiselect.p-multiselect.p-multiselect-chip .p-multiselect-token {
  margin-right: 0;
}
.multiselect-dropdown .p-component.p-multiselect-items .p-multiselect-item > span {
  display: flex;
  width: 100%;
}

.multiselect-dropdown.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 10px;
}

.multiselect.p-multiselect {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 36px;
  font-size: 12px;
  display: flex;
  align-items: center;
  border: 1px solid #39b54a;
  border-radius: 3px;
}
.multiselect.p-multiselect:not(.p-disabled):hover {
  border: 1px solid #2bb03d;
}

.multiselect.p-multiselect.p-multiselect-chip .p-multiselect-token {
  background-color: #39b54a;
  border-radius: 3px;
}

.multiselect-dropdown.p-multiselect-panel .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #39b54a;
  background: #39b54a;
}
.multiselect-dropdown.p-multiselect-panel .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #39b54a;
}
.multiselect-dropdown.p-multiselect-panel .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #39b54a;
  background: #39b54a;
}
.multiselect-dropdown.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: #eefff2;
  color: #39b54a;
}
.multiselect-dropdown.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: inset 0 0 0 0.15rem #c9e6d0;
}
.multiselect.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.15rem #c9e6d0;
  border-color: #39b54a;
}
.multiselect-dropdown.p-multiselect-panel .p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #c9e6d0;
  border-color: #39b54a;
}
.multiselect-dropdown.p-multiselect-panel .p-inputtext:enabled:hover {
  border-color: #39b54a;
}
